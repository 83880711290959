import React from 'react';
import { prop, path } from 'ramda';
import { compose, withState, withProps, withHandlers } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import moment from 'moment-timezone';

import editOrder from '/src/services/stripe/editOrder';
import DataTable from '/src/components/molecules/SortableTable';
import FormGroup from '/src/components/molecules/FormGroup';
import { currencyToString } from '/src/utils/currency';

const Table = ({ columns, data }) => {
  return data ? (
    <DataTable
      id="ordersTable"
      keyProp="id"
      data={data}
      columns={columns}
      striped
    />
  ) : (
    'No orders to show'
  );
};

const columnsSelector = createSelector(
  prop('updateOrder'),
  prop('isSubmitting'),
  (updateOrder, isSubmitting) => [
    {
      title: 'Time created',
      property: 'created',
      renderItem: item =>
        moment(item.created * 1000).format('YYYY/MM/DD hh:mma'),
    },
    {
      title: 'Name',
      property: path(['shipping', 'name']),
    },
    {
      title: 'Address',
      property: path(['shipping', 'address']),
      noSort: true,
      renderItem: ({ shipping: { address } }) => {
        const { city, state, country, postal_code, line1, line2 } = address;

        return (
          <address>
            <div>{line1}</div>
            {line2 && <div>{line2}</div>}
            <div>{`${city}, ${state} ${postal_code} ${country}`}</div>
          </address>
        );
      },
    },
    {
      title: 'Amount paid',
      property: 'amount',
      renderItem: ({ amount }) => currencyToString(amount),
    },
    {
      title: 'Status',
      property: 'status',
      renderItem: item => (
        <FormGroup
          id={`orderStatus__${item.id}`}
          label="status"
          small
          value={item.status}
          inputComponent="select"
          inputProps={{
            children: ['paid', 'fulfilled'].map(statusOption => (
              <option key={statusOption} value={statusOption}>
                {statusOption}
              </option>
            )),
            disabled: isSubmitting || item.status === 'fulfilled',
            onChange: event =>
              updateOrder({
                id: item.id,
                status: event.target.value,
              }),
          }}
        />
      ),
    },
  ]
);

const propSelectors = createStructuredSelector({
  columns: columnsSelector,
});

export default compose(
  withState('submitting', 'setSubmitting', false),
  withHandlers({
    updateOrder: ({ setSubmitting, reload }) => async order => {
      setSubmitting(true);
      await editOrder(order);
      await reload();
      setSubmitting(false);
    },
  }),
  withProps(propSelectors)
)(Table);
