import React from 'react';

import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import ContentSection from '/src/components/atoms/ContentSection';

const AdminLayout = ({ children }) => (
  <Layout hideHeader>
    <SEO title={`Admin | Shop`} />
    <ContentSection>
      <h1 className="heading3">EoA Shop Admin</h1>
    </ContentSection>
    <ContentSection light>{children}</ContentSection>
  </Layout>
);

export default AdminLayout;
