import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectors, actions } from '/src/store/sortableTable';
import DataTable from '../DataTable';

export class SortableTable extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sortedData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sortBy: PropTypes.string,
    isSortAscending: PropTypes.bool,
    setSort: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sortBy: null,
    isSortAscending: false,
  };

  handleSortChange = title => {
    const { id, setSort } = this.props;
    setSort(id, title);
  };

  render() {
    const {
      sortedData,
      sortBy,
      isSortAscending,
      setSort,
      ...dataTableProps
    } = this.props;

    return (
      <DataTable
        {...dataTableProps}
        data={sortedData}
        sortBy={sortBy}
        isSortAscending={isSortAscending}
        onSortChange={this.handleSortChange}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sortBy: selectors.sortBySelector,
  isSortAscending: selectors.isSortAscendingSelector,
  sortedData: selectors.sortedDataSelector,
});

const mapDispatchToProps = {
  setSort: actions.setSort,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SortableTable);
