import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';

import Button from '/src/components/atoms/Button';

const LoginForm = () => {
  const { settings, loginProvider } = useIdentityContext();

  return (
    <div>
      {settings && settings.external.google ? (
        <Button onClick={() => loginProvider('google')}>
          Login with Google
        </Button>
      ) : (
        'No external auth provider has been set up'
      )}
    </div>
  );
};

export default LoginForm;
