import { EDIT_ORDER_ENDPOINT } from '/src/constants';

export default async order =>
  fetch(EDIT_ORDER_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      order,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });
