import React from 'react';
import { Router } from '@reach/router';
import { useIdentityContext } from 'react-netlify-identity';

import ContentSection from '/src/components/atoms/ContentSection';
import PrivateRoute from '/src/components/atoms/PrivateRoute';
import LoginForm from '/src/components/molecules/LoginForm';
import AdminDashboard from '/src/components/organisms/AdminDashboard';
import AdminLayout from '/src/templates/AdminLayout';
import Orders from '/src/components/organisms/AdminOrders';

const Default = () => {
  const { isLoggedIn } = useIdentityContext();

  return (
    <ContentSection center>
      {isLoggedIn ? <AdminDashboard /> : <LoginForm />}
    </ContentSection>
  );
};

const ShopAdminPage = () => (
  <AdminLayout>
    <Router>
      <PrivateRoute path="/shop/admin/orders" component={Orders} />
      <Default default />
    </Router>
  </AdminLayout>
);

export default ShopAdminPage;
