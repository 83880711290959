import React from 'react';
import { Link } from 'gatsby';

import Button from '/src/components/atoms/Button';
import ContentSection from '/src/components/atoms/ContentSection';

const AdminDashboard = () => (
  <ContentSection>
    <Button component={Link} to="/shop/admin/orders">
      Manage Orders
    </Button>
  </ContentSection>
);

export default AdminDashboard;
