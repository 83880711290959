import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import ContentSection from '/src/components/atoms/ContentSection';
import Spinner from '/src/components/atoms/Spinner';
import Table from './Table';

const orderQuery = gql`
  query EoaOrders {
    stripeOrders {
      id
      status
      amount
      created
      items {
        object
        amount
        currency
        description
        parent
        quantity
      }
      shipping {
        name
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
      }
    }
  }
`;

const StripeOrders = () => {
  const { loading, error, data, refetch } = useQuery(orderQuery);

  return !error ? (
    !loading ? (
      <ContentSection>
        <Table data={data.stripeOrders} reload={refetch} />
      </ContentSection>
    ) : (
      <Spinner />
    )
  ) : (
    <p>Error loading orders</p>
  );
};

export default StripeOrders;
