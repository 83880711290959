import React from 'react';
import { navigate } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity';

const LOGIN_PAGE = '/shop/admin';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoggedIn } = useIdentityContext();

  if (!isLoggedIn && location.pathname !== LOGIN_PAGE) {
    navigate(LOGIN_PAGE);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
